"use client";

import { GE } from ".constants/gtmEvents";
import {
  PATH_ABOUT_US,
  PATH_ANDROID,
  PATH_BLOG,
  PATH_COMPLAINT_POLICY,
  PATH_CONTACT_US,
  PATH_DEVELOPER,
  PATH_FAQ,
  PATH_IOS,
  PATH_MAKE_MONEY,
  PATH_PRELAND_AI_CLOTHES_REMOVER,
  PATH_PRELAND_DEEPNUDE,
  PATH_PRELAND_DEEPSUKEBE,
  PATH_PRELAND_MUKE_AI,
  PATH_PRELAND_NUDE_AI,
  PATH_PRELAND_NUDIFY,
  PATH_PRELAND_UDRESS_AI,
  PATH_PRESS,
  PATH_PRIVACY,
  PATH_PROCESSING_PRIVACY,
  PATH_REFUND,
  PATH_TERMS,
  PATH_USE_POLICY,
  SOCIAL_CHANNEL_TWITTER,
} from ".constants/links";
import { PORTALS } from ".constants/portals";
import { EC_COUNTRIES_LIST } from ".constants/texts";
import {
  Box,
  Button,
  GetAppButton,
  TextLink,
  Text,
  ToggleBox,
  usePortalContext,
  DataInput,
  PlateContent,
} from "@cloai/uikit";
import clsx from "clsx";
import _, { includes } from "lodash";
import { Route } from "next";
import { signOut } from "next-auth/react";
import { useLocale, useTranslations } from "next-intl";
import Image from "next/image";
import Link from "next/link";
import {
  FC,
  memo,
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useMemo,
} from "react";

import { usePathname } from "@/i18n";
import {
  useHeadersContext,
  useProfileContext,
  useGTMContext,
} from "@/providers";
import IconAndroid from "@/styles/image/icon-android.svg";
import IconApple from "@/styles/image/icon-apple.svg";
import IconX from "@/styles/image/social-buttons/twitter-x.svg";
import styles from "@/styles/pages/footer.module.scss";
import { getGTM } from "@/utils/gtm";
import { getCountry } from "@/utils/headers";

import { AsulabelLogo } from "./AsulabelLogo";
import { useCompany } from "./hooks/useCompany";
import { LanguageCode, useFlag, useLanguage } from "./hooks/useLanguage";
import { useSupport } from "./hooks/useSupport";
import { RtaLogo } from "./RtaLogo";

type TMenuItemProps = {
  href?: string;
  text: string;
  icon?: string;
  onClick?: MouseEventHandler<HTMLElement>;
};

const MenuItem: FC<TMenuItemProps> = memo(({ href, text, icon, onClick }) => {
  const menuItemContent = (
    <>
      <span>{text}</span>
      <span
        className={clsx("material-icons", styles.icon)}
        data-icon={icon || "chevron_right"}
      />
    </>
  );

  return (
    <li>
      {href ? (
        <Link
          href={href as Route}
          className={styles.menu_link}
          onClick={onClick}
        >
          {menuItemContent}
        </Link>
      ) : (
        <span className={styles.menu_link} onClick={onClick}>
          {menuItemContent}
        </span>
      )}
    </li>
  );
});

MenuItem.displayName = "MenuItem";

export const Footer: FC = () => {
  const t = useTranslations("Footer");
  const profile = useProfileContext();
  const headers = useHeadersContext();
  const { openPortal } = usePortalContext();
  const { sendGTM } = useGTMContext();

  const pathname = usePathname();
  const locale = useLocale() as LanguageCode;

  const {
    companyLower,
    isClothoff,
    isDeepnudeNET,
    isOkbra,
    supportEmail,
    coopEmail,
    companyAddress,
  } = useCompany();
  const currentLanguage = useLanguage();
  const onClickSupport = useSupport();
  const flag = useFlag();

  const country = getCountry(headers);

  const onLogout = useCallback(() => {
    signOut();
  }, []);

  const openLanguageModal = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    sendGTM({ event: GE.CHOOSE_LANGUAGE_FOOTER });
    openPortal(PORTALS.ChangeLang);
  };

  const socialLinks = useMemo(
    () => (
      <div className={styles.social_block}>
        <Link
          className={clsx(styles.social_link, styles.twitter_x)}
          href={SOCIAL_CHANNEL_TWITTER(companyLower)}
          aria-label="twitter"
          target="_blank"
        >
          <Image src={IconX} alt="X" width={32} height={32} />
        </Link>

        {/* <Link
          className={clsx(styles.social_link, styles.telegram)}
          href={SOCIAL_CHANNEL_TELEGRAM(host)}
          aria-label="telegram"
          target="_blank"
        >
          <Image src={IconTelegram} alt="Telegram" width={32} height={32} />
        </Link> */}
      </div>
    ),
    [companyLower],
  );

  const appButtons = useMemo(
    () => (
      <div className={styles.get_app_btns}>
        <GetAppButton
          href={PATH_IOS}
          image={IconApple}
          imageAlt={t("imgMeta.setup.alt.ios")}
          title={t("getAppOn")}
          text="Apple iOS"
        />
        <GetAppButton
          href={PATH_ANDROID}
          image={IconAndroid}
          imageAlt={t("imgMeta.setup.alt.android")}
          title={t("getAppOn")}
          text="Android"
        />
      </div>
    ),
    [t],
  );

  const supportLinks = useMemo(
    () => (
      <>
        <span>
          <a
            href={`mailto:${supportEmail}`}
            className={styles.support_link}
            target="_blank"
          >
            {supportEmail}
          </a>
          <a
            href={`mailto:${coopEmail}`}
            className={styles.support_link}
            target="_blank"
          >
            {coopEmail}
          </a>
        </span>

        {profile?.isAdmin && (
          <PlateContent gap={12} theme="lightblue-flat">
            <Text
              text={"This block is only available to admins"}
              color="orange"
              center
            />
            <Button
              text="Log out"
              wide
              centerText
              color="regular"
              onClick={onLogout}
            />
          </PlateContent>
        )}
      </>
    ),
    [supportEmail, coopEmail, profile?.isAdmin, onLogout],
  );

  const geoLegalInfo = _.includes(EC_COUNTRIES_LIST, country) && (
    <div className={styles.dislaimer}>{t("geoDislaimer")}</div>
  );

  const asulableInfo = isClothoff && (
    <div className={styles.dislaimer}>
      {t.rich("asulableInfo", {
        link: () => (
          <TextLink href="mailto:team@asulabel.com" text="team@asulabel.com" />
        ),
      })}
    </div>
  );

  const isShowChangeLangButton =
    !process.env.NEXT_PUBLIC_IS_CN &&
    !includes(
      [
        PATH_BLOG,
        PATH_PRELAND_AI_CLOTHES_REMOVER,
        PATH_PRELAND_DEEPNUDE,
        PATH_PRELAND_DEEPSUKEBE,
        PATH_PRELAND_MUKE_AI,
        PATH_PRELAND_NUDE_AI,
        PATH_PRELAND_NUDIFY,
        PATH_PRELAND_UDRESS_AI,
      ],
      pathname,
    );

  const GTM = getGTM(companyLower);

  return (
    <footer className={styles.footer}>
      <nav>
        <hr />
        <ul className={styles.menu_list}>
          {profile && isOkbra && (
            <DataInput name={"Your Profile ID:"} value={profile.id} />
          )}
          <MenuItem href={PATH_MAKE_MONEY} text={t("makeMoney")} />
          {isClothoff && <MenuItem href={PATH_DEVELOPER} text={t("tryAPI")} />}
          <MenuItem href={PATH_PRIVACY} text={t("privacyPolicy")} />
          <MenuItem href={PATH_COMPLAINT_POLICY} text={t("complaintPolicy")} />
          {_.includes(EC_COUNTRIES_LIST, country) && (
            <MenuItem
              href={PATH_PROCESSING_PRIVACY}
              text={t("dataProcessingPolicy")}
            />
          )}
          <MenuItem href={PATH_TERMS} text={t("termsofService")} />
          <MenuItem href={PATH_USE_POLICY} text={t("usePolicy")} />
          {!isOkbra && <MenuItem href={PATH_REFUND} text={t("refund")} />}
          {!isOkbra && <MenuItem href={PATH_PRESS} text={t("smi")} />}
          {(isClothoff || isOkbra) && (
            <MenuItem href={PATH_ABOUT_US} text={t("about_us")} />
          )}
          <MenuItem href={PATH_CONTACT_US} text={t("contactUs")} />
          {(isClothoff || isOkbra) && (
            <MenuItem href={PATH_FAQ} text={t("faq")} />
          )}
          {isClothoff && <MenuItem href={PATH_BLOG} text={t("blog")} />}

          {isClothoff && (
            <ToggleBox
              theme="clean"
              title={
                <Text text={t("aiTools.title")} style={{ opacity: 0.72 }} />
              }
            >
              <PlateContent wide>
                <MenuItem
                  href={PATH_PRELAND_UDRESS_AI}
                  text={t("aiTools.undressAi")}
                />
                <MenuItem
                  href={PATH_PRELAND_DEEPNUDE}
                  text={t("aiTools.deepnudeAi")}
                />
                <MenuItem
                  href={PATH_PRELAND_NUDIFY}
                  text={t("aiTools.nudifyAi")}
                />
                <MenuItem
                  href={PATH_PRELAND_MUKE_AI}
                  text={t("aiTools.mukeAi")}
                />
                <MenuItem
                  href={PATH_PRELAND_NUDE_AI}
                  text={t("aiTools.nudeAi")}
                />
                <MenuItem
                  href={PATH_PRELAND_AI_CLOTHES_REMOVER}
                  text={t("aiTools.aiClothesRemover")}
                />
                <MenuItem
                  href={PATH_PRELAND_DEEPSUKEBE}
                  text={t("aiTools.deepsukebeAi")}
                />
              </PlateContent>
            </ToggleBox>
          )}

          {(!isDeepnudeNET && GTM) || isOkbra ? (
            <MenuItem
              text={t("support")}
              icon="support"
              onClick={onClickSupport}
            />
          ) : null}

          {profile && (
            <>
              <MenuItem
                text={t("activatePromocode")}
                icon="local_activity"
                onClick={() => openPortal(PORTALS.ActivatePromocode)}
              />
            </>
          )}
        </ul>
      </nav>

      {!isOkbra && socialLinks}

      {!isOkbra && appButtons}

      {isShowChangeLangButton && (
        <Box fit>
          <Button
            iconLeft={flag(locale)}
            iconRight="arrow_drop_down"
            text={currentLanguage}
            color="regular"
            wide
            centerText
            onClick={openLanguageModal}
          />
        </Box>
      )}

      {supportLinks}

      <Box>
        <div className={styles.dislaimer}>
          {t("dislaimer1")}
          <br />
          {t("dislaimer2")}
          <br />
          {t("dislaimer3")}
          <br />
          {_.includes(EC_COUNTRIES_LIST, country) &&
            t.rich("dislaimer4", {
              link: () => (
                <TextLink href={`mailto:${supportEmail}`} text={supportEmail} />
              ),
            })}
        </div>

        {geoLegalInfo}

        {asulableInfo}

        {companyAddress && (
          <div className={styles.legal_info}>{companyAddress}</div>
        )}
      </Box>

      <Box gap={32} center>
        <AsulabelLogo />
        <RtaLogo />
      </Box>
    </footer>
  );
};
